import React, { useState, useEffect, PureComponent } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Helmet } from "react-helmet";
import { Header, Grid, MainContent, Sidebar } from "~components/structure";
import { css } from "@emotion/react";
import { Emoji } from "emoji-mart";
import color from "~styles/color";
import { AiOutlineArrowRight, AiOutlineStar } from "react-icons/ai";
import { IoSwapHorizontalOutline, IoNewspaperOutline } from "react-icons/io5";
import { BiCodeAlt } from "react-icons/bi";
import { VscRepoForked } from "react-icons/vsc";
import { SiGooglecolab } from "react-icons/si";
import { Radio, Tooltip } from "antd";
import { BsFillPlayFill, BsCaretDownFill } from "react-icons/bs";
import { GoRepo, GoChevronDown } from "react-icons/go";
import { RiFilePaper2Line } from "react-icons/ri";

import demo1Metadata from "~static/demo1/metadata.json";
import lightingDemoMetadata from "~static/lighting-demo/metadata.json";

import "@allenai/varnish/dist/theme.css";
import { StaticImage } from "gatsby-plugin-image";
import pg0 from "~static/demo2/0.svg";
import pg1 from "~static/demo2/1.svg";
import pg2 from "~static/demo2/2.svg";
import fps from "~static/floorplans.svg";
import classNames from "classnames/bind";

const awsPath = "https://procthor-10k.s3.us-west-2.amazonaws.com/static/";

function FooterA(props: { href: string; children: React.ReactNode }) {
  return (
    <a href={props.href} className="!text-ai2blue-300">
      {props.children}
    </a>
  );
}

function Footer() {
  return (
    <div className="bg-ai2blue-1000 text-white py-6 text-center px-8">
      <FooterA href="//allenai.org/">
        © The Allen Institute for Artificial Intelligence
      </FooterA>{" "}
      - All Rights Reserved |{" "}
      {[
        ["//allenai.org/privacy-policy", "Privacy Policy"],
        ["//allenai.org/terms", "Terms of Use"],
        ["//allenai.org/business-code-of-conduct", "Business Code of Conduct"],
      ]
        .map(([href, text]) => (
          <FooterA key={href} href={href}>
            {text}
          </FooterA>
        ))
        // @ts-ignore
        .reduce((prev, curr) => [prev, " | ", curr])}
    </div>
  );
}

const LANGUAGE_COLORS = {
  PyTorch: "bg-red-600",
  Unity: "bg-green-600",
  Python: "bg-orange-600",
  Colab: "bg-violet-600",
  React: "bg-blue-600",
};

function Code(props: {
  emoji?: string;
  type: string;
  owner: string;
  name: string;
  description: string;
  language: string;
}) {
  const [stargazersCount, setStargazersCount] = useState(undefined),
    [forksCount, setForksCount] = useState(undefined);

  useEffect(() => {
    const getCount = async () => {
      const res = await fetch(
        `https://api.github.com/repos/${props.owner}/${props.name}`
      );
      const resJson = await res.json();
      setStargazersCount(resJson.stargazers_count);
      setForksCount(resJson.forks_count);
    };
    getCount();
  }, [props.owner, props.name]);

  return (
    <div className="rounded-md border-gray-200 border-[1.5px] p-3 bg-white">
      <div>
        <GoRepo className="inline-block align-middle" />{" "}
        <a
          className="inline-block align-middle ml-1"
          href={`//github.com/${props.owner}/${props.name}`}
          target="_blank"
        >
          {props.owner}
          <span className="font-semibold">/{props.name}</span>
        </a>
        <div className="inline-block ml-2 rounded-full border-gray-300 border-[1px] px-[6px] text-gray-600">
          {props.type}
        </div>
      </div>
      <div className="mt-3 text-gray-600">
        {props.emoji ? <Emoji emoji={props.emoji} size={14} /> : undefined}{" "}
        {props.description}
      </div>
      <div className="mt-3">
        <div
          className={`rounded-full w-3.5 h-3.5 ${
            LANGUAGE_COLORS[props.language]
          } inline-block align-middle`}
        />
        <div className="inline-block ml-1 text-gray-600 align-middle w-16">
          {props.language}
        </div>
        <a
          href={`//github.com/${props.owner}/${props.name}/stargazers`}
          target="_blank"
          className="hover:underline !text-gray-600 hover:!text-ai2blue-500"
        >
          <div className="ml-4 inline-block align-middle text-lg">
            <AiOutlineStar />
          </div>
          <div className="inline-block ml-[2px] align-middle">
            {stargazersCount}
          </div>
        </a>
        <a
          href={`//github.com/${props.owner}/${props.name}/network/members`}
          target="_blank"
          className="hover:underline !text-gray-600 hover:!text-ai2blue-500"
        >
          <div className="ml-4 inline-block align-middle text-lg">
            <VscRepoForked />
          </div>
          <div className="inline-block ml-[2px] align-middle">{forksCount}</div>
        </a>
      </div>
    </div>
  );
}

function ColorBox(props: { className: string; name: string }) {
  return (
    <div className="inline-block mr-6">
      <div
        className={classNames(
          "align-middle inline-block h-5 w-5",
          props.className
        )}
      />
      <div className="align-middle inline-block ml-1">{props.name}</div>
    </div>
  );
}

function TextAnnotation(props: { text: string }) {
  return (
    <div
      css={css`
        font-size: 16px;
        margin-bottom: 12px;
        margin-top: 28px;
      `}
      className="mt-7 mb-3 text-xs md:text-base"
    >
      {props.text}
    </div>
  );
}

function CoverButton(props: {
  target: string;
  href: string;
  children: React.ReactNode;
}) {
  return (
    <a href={props.href} target={props.target}>
      <div></div>
    </a>
  );
}

function Cover() {
  return (
    <div
      css={css`
        margin-top: 0px;
        height: 100vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("/procthor15.jpg");
        > h1,
        h2,
        h3 {
          font-family: Georgia, serif;
        }
      `}
    >
      <div className="w-10/12 mx-auto max-w-[1500px]">
        <img
          src="/icons/procthor.svg"
          className="w-full pb-6 pt-[15vh]"
          css={css`
            filter: drop-shadow(0px 0px 15px #ffffffba);
          `}
        />
        <h3
          css={css`
            text-shadow: 0px 0px 15px #ffffff70;
          `}
          className="text-[18px] md:text-2xl xl:text-4xl my-0 text-white font-normal"
        >
          ProcTHOR enables Embodied AI to scale by orders of magnitude by
          procedurally generating interactive 3D environments.
        </h3>
        <div
          css={css`
            margin-top: 20px;
            @media (min-width: 768px) {
              margin-top: 35px;
            }

            a > div,
            > div {
              background: rgba(255, 255, 255, 0.85);
              display: inline-block;
              color: black;
              margin-right: 15px;
              margin-bottom: 15px;
              border-radius: 5px;
              transition-duration: 0.3s;
              &:hover {
                background: rgba(255, 255, 255, 0.95);
              }

              font-size: 14px;
              padding: 5px 10px;
              @media (min-width: 768px) {
                font-size: 18px;
                padding: 7px 15px;
              }
            }
          `}
        >
          <a href="#explore">
            <div>
              <GoChevronDown
                css={css`
                  display: inline-block;
                  vertical-align: middle;
                  margin-top: -4px;
                  margin-right: 3px;

                  font-size: 21px;
                  @media (min-width: 768px) {
                    font-size: 23px;
                  }
                `}
              />{" "}
              EXPLORE
            </div>
          </a>
          <a href="//arxiv.org/abs/2206.06994" target="_blank">
            <div>
              <RiFilePaper2Line
                css={css`
                  font-size: 23px;
                  display: inline-block;
                  vertical-align: middle;
                  margin-top: -4px;
                  margin-right: 3px;
                `}
              />{" "}
              PAPER
            </div>
          </a>
          <a
            href="//colab.research.google.com/drive/1Il6TqmRXOkzYMIEaOU9e4-uTDTIb5Q78"
            target="_blank"
          >
            <div>
              <SiGooglecolab
                css={css`
                  font-size: 23px;
                  display: inline-block;
                  vertical-align: middle;
                  margin-top: -4px;
                  margin-right: 3px;
                `}
              />{" "}
              COLAB
            </div>
          </a>
          {/* <div>
            <BsFillPlayFill
              css={css`
                font-size: 23px;
                display: inline-block;
                vertical-align: middle;
                margin-top: -4px;
                margin-right: 2px;
              `}
            />{" "}
            VIDEO
          </div> */}
        </div>
      </div>
    </div>
  );
}

function Customizability() {
  return (
    <Section
      parentClassNames="bg-gray-50"
      text={
        <>
          <b>Customizability</b>. ProcTHOR can construct custom scene types,
          such as classrooms, libraries, and offices.
        </>
      }
    >
      <div
        css={css`
          > div {
            width: 32%;
            display: inline-block;
            text-align: left;
            font-size: 16px;
            > p {
              text-transform: uppercase;
              margin-bottom: 8px;
            }
          }
        `}
      >
        <div
          css={css`
            margin-right: 2%;
          `}
        >
          <p>Classroom</p>
          <StaticImage
            src={`../../static/envs/classroom.jpg`}
            alt="Classroom Environment"
          />
        </div>
        <div
          css={css`
            margin-right: 2%;
          `}
        >
          <p>Library</p>
          <StaticImage
            src={`../../static/envs/library.jpg`}
            alt="Library Environment"
          />
        </div>
        <div>
          <p>Office</p>
          <StaticImage
            src={`../../static/envs/office.jpg`}
            alt="Office Environment"
          />
        </div>
      </div>
    </Section>
  );
}

function MainDemo(props: { leftWidth: string; rightWidth: string }) {
  const [prefix, setPrefix] = useState("0_"),
    [demo1_i, setDemo1_i] = useState(0),
    [propertyType, setPropertyType] = useState("Apartment");

  const agentSpots = demo1Metadata[propertyType][demo1_i]["agentSpots"],
    demo1BaseDir = `demo1`,
    demo1Dir = `${demo1BaseDir}/${propertyType}_${demo1_i}`;

  return (
    <div id="explore" className="py-16 md:py-44 relative">
      <div className="max-w-screen-2xl w-10/12 mx-auto md:mb-32 absolute inset-0">
        <div
          css={css`
            text-align: left;
            margin-bottom: 28px;
          `}
          className="mb-10"
        >
          <TextAnnotation text="PROPERTY TYPE" />
          <div className="scale-75 md:scale-100 origin-top-left">
            <Radio.Group
              value={propertyType}
              onChange={(e) => {
                setPropertyType(e.target.value);
                setPrefix("0_");
                setDemo1_i(0);
              }}
              size="large"
              css={css`
                * {
                  font-size: 23px !important;
                }
              `}
            >
              <Radio.Button value="Studio">Studio</Radio.Button>
              <Radio.Button value="Apartment">Apartment</Radio.Button>
              <Radio.Button value="House">House</Radio.Button>
              <Radio.Button value="MultiFamily">Multi-Family</Radio.Button>
            </Radio.Group>
          </div>
        </div>
      </div>

      <div className="max-w-screen-2xl w-10/12 mx-auto pt-20 md:pt-6">
        <div
          css={css`
            width: ${props.leftWidth};
            display: inline-block;
            vertical-align: middle;
            position: relative;
            z-index: 999 !important;
          `}
        >
          <img
            src={`${awsPath}${demo1Dir}_top.jpg`}
            alt=""
            css={css`
              width: 100%;
              pointer-events: none;
            `}
            className="noselect"
          />
          {agentSpots.map((agentSpot, index) => {
            const spotPrefix = `${index}_`;
            return (
              <div
                css={css`
                  border-radius: 30px;
                  position: absolute;
                  height: 60px;
                  /* background: rgba(255, 255, 255, 0.725);
                  background: ${prefix ==
                  spotPrefix
                    ? "rgba(0, 0, 0, 0.75)"
                    : "rgba(255, 255, 255, 0.725)"}; */
                  color: ${prefix == spotPrefix ? "white" : "black"};
                  top: ${agentSpot.y * 100}%;
                  left: ${agentSpot.x * 100}%;
                  transform: translate(-50%, -50%);
                  text-align: center;
                  font-size: 20px;
                  &:hover {
                    cursor: pointer !important;
                  }
                `}
                onClick={() => {
                  setPrefix(spotPrefix);
                }}
                key={index}
                className="pin w-[25px] md:w-[45px]"
              >
                <div
                  css={css`
                    position: absolute;
                    top: -5px;
                    left: ${45 / 2}px;
                    transform: translate(-50%, -50%);
                    z-index: 99 !important;
                  `}
                  className="noselect text-xs md:text-base left-[12.5px] md:left-[22.5px]"
                >
                  {index + 1}
                </div>
                <Pin
                  fill={
                    prefix == spotPrefix
                      ? "rgba(0, 0, 0, 0.75)"
                      : "rgba(255, 255, 255, 0.725)"
                  }
                  stroke={
                    prefix == spotPrefix
                      ? "rgba(255, 255, 255, 0.25)"
                      : "rgba(0, 0, 0, 0.25)"
                  }
                />
              </div>
            );
          })}
        </div>
        <AiOutlineArrowRight
          css={css`
            position: absolute;
            /* top: 50%; */
            left: ${(42.5 + 100 - 35 - 2) / 2}%;
            transform: translate(-50%, -50%);
            font-size: 50px;
            color: black;
            text-align: center;

            font-size: 25px;
            top: 43.5%;

            @media (min-width: 768px) {
              font-size: 50px;
            }
          `}
        />
        <div
          css={css`
            width: ${props.rightWidth};
            display: inline-block;
            vertical-align: middle;
            margin-left: 20%;
          `}
        >
          <Image360
            images={[...Array(15).keys()].map(
              (i) => `${awsPath}${demo1Dir}_${prefix}${i + 1}.jpg`
            )}
          />
        </div>
        <div
          css={css`
            width: ${props.leftWidth};
            display: inline-block;
            vertical-align: top;
          `}
        >
          <div
            css={css`
              text-align: center;
              margin-top: 13px;
              margin-bottom: 13px;
            `}
          >
            {[...Array(10).keys()].map((i) => (
              <div
                key={i}
                className="w-[8px] lg:w-[15px]"
                css={css`
                  display: inline-block;
                  height: 2px;
                  border-radius: 2px;
                  background-color: ${demo1_i == i
                    ? color.gray10
                    : color.gray5};
                  margin: 0px 2px;
                `}
              />
            ))}
          </div>
          <div className="overflow-x-hidden">
            <div
              className="w-[200%] duration-[450ms]"
              style={{
                marginLeft: `${getMarginDist(demo1_i)}%`,
              }}
            >
              {[...Array(10).keys()].map((i) => (
                <ClickableImage
                  src={`${awsPath}${demo1BaseDir}/${propertyType}_${i}_top_small.jpg`}
                  onClick={() => {
                    setDemo1_i(i);
                    setPrefix("0_");
                  }}
                  key={i}
                  isSelected={demo1_i === i}
                  className="!w-[calc(10%-2px)] !lg:w-[calc(10%-10px)] !m-[0px_1px] !lg:m-[0px_5px] p-[6px]"
                />
              ))}
            </div>
          </div>
        </div>
        <div
          css={css`
            width: ${props.rightWidth};
            margin-left: 20%;
            display: inline-block;
            vertical-align: top;
            color: ${color.gray7};
          `}
          className="noselect md:mt-[-25px] lg:mt-[-30px] xl:mt-[-50px]"
        >
          <div
            css={css`
              text-align: center;
            `}
          >
            <IoSwapHorizontalOutline
              css={css`
                font-size: 25px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 2px;
              `}
            />{" "}
            Drag to Rotate
          </div>
          <div
            css={css`
              font-size: 16px;
              margin-bottom: 10px;
              z-index: 1 !important;
              position: relative;
              margin-top: 35px;
            `}
          >
            PIN LOCATION
          </div>
          <div
            css={css`
              margin-top: 30px;
            `}
          >
            {agentSpots.map((agentSpot, index) => {
              const spotPrefix = `${index}_`;
              return (
                <div
                  css={css`
                    border-radius: 30px;
                    display: inline-block;
                    position: relative;
                    height: 60px;
                    color: ${prefix == spotPrefix ? "white" : "black"};
                    text-align: center;
                    font-size: 20px;
                    margin-right: 0px;
                    &:hover {
                      cursor: pointer !important;
                    }
                  `}
                  onClick={() => {
                    setPrefix(spotPrefix);
                  }}
                  key={index}
                  className="pin w-[25px] md:w-[45px]"
                >
                  <div
                    css={css`
                      position: absolute;
                      top: -3px;
                      left: ${39.5 / 2}px;
                      transform: translate(-50%, -50%);
                      z-index: 99 !important;
                      font-size: 16px;
                      color: ${prefix == spotPrefix ? "white" : color.gray6};
                    `}
                    className="noselect text-xs md:text-base left-[11.25px] md:left-[19.75px]"
                  >
                    {index + 1}
                  </div>
                  <PinStroke
                    fill={
                      prefix == spotPrefix ? "rgba(0, 0, 0, 0.75)" : color.gray3
                    }
                    stroke={
                      prefix == spotPrefix
                        ? "rgba(255, 255, 255, 0.0)"
                        : color.gray5
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function ClickableImage(props: {
  src: string;
  isSelected: boolean;
  onClick: () => void;
  className?: string;
}) {
  return (
    <img
      src={props.src}
      className={classNames(
        "inline-block w-12 mr-3 mb-3 lg:w-20 lg:mr-5 duration-300",
        {
          "opacity-30 hover:opacity-50 hover:cursor-pointer duration-300": !props.isSelected,
        },
        props.className
      )}
      onClick={props.onClick}
    />
  );
}

function Section(props: {
  children: React.ReactNode;
  text: React.ReactNode | string;
  parentClassNames?: string;
}) {
  return (
    <div className={classNames("py-16 md:py-44", props.parentClassNames)}>
      <div className="max-w-screen-2xl w-10/12 mx-auto md:mb-32">
        <p
          css={css`
            max-width: 780px;
            margin-bottom: 65px;
            font-size: clamp(1rem, 4vw, 28px);
            width: 80%;
            text-align: left;
            font-family: georgia, serif;
            color: ${color.gray11};
          `}
          className="mb-4 md:mb-16"
        >
          {props.text}
        </p>
        {props.children}
      </div>
    </div>
  );
}

function Results() {
  return (
    <Section
      text={
        <>
          <b>Results</b>. Pre-training with ProcTHOR improves downstream
          performance. Zero-Shot performance, from models pre-trained on
          ProcTHOR, often beats the same models trained on the training data
          from the benchmark it is evaluated on.
        </>
      }
    >
      <div
        css={css`
          font-family: georgia, serif;
          > div {
            display: inline-block;
            height: 350px;
            /* background: ${color.gray8}; */
            > h4 {
              font-size: 20px;
              margin-bottom: 10px;
              font-weight: normal;
            }
          }
        `}
      >
        <Result
          challenge="RoboTHOR ObjectNav"
          image={
            <StaticImage
              src={`../../static/tasks/robothor2.jpg`}
              alt="RoboTHOR ObjectNav Task"
            />
          }
          baseline={0.47}
          zeroShot={0.55}
          fineTune={0.65}
          metricMin={0.2}
          metricMax={0.8}
          baselineText="trained on RoboTHOR"
          description="Semantic Object Navigation in Dorm Styled, 3D Artist-Designed Scenes"
          metric="Success Rate"
          fineTuneText="fine-tuned on RoboTHOR"
        />
        <Result
          challenge="Habitat ObjectNav"
          description="Semantic Object Navigation in Multi-Floor, 3D Matterport Scanned Scenes"
          image={
            <StaticImage
              src={`../../static/tasks/habitat.jpg`}
              alt="Habitat ObjectNav Task"
            />
          }
          baseline={0.28}
          zeroShot={0.077}
          fineTune={0.318}
          metricMin={0}
          metricMax={0.4}
          baselineText="trained on Habitat"
          metric="SPL"
          fineTuneText="fine-tuned on Habitat"
        />
        <Result
          challenge="AI2-iTHOR ObjectNav"
          image={
            <StaticImage
              src={`../../static/tasks/ithor.jpg`}
              css={css`
                margin-top: -20px;
              `}
              alt="AI2-iTHOR ObjectNav Task"
            />
          }
          baseline={0.68}
          zeroShot={0.76}
          fineTune={0.78}
          metricMin={0.55}
          metricMax={0.85}
          baselineText="trained on AI2-iTHOR"
          description="Semantic Object Navigation in Room-Sized, 3D Artist-Designed Scenes"
          metric="Success Rate"
          fineTuneText="fine-tuned on AI2-iTHOR"
        />
        <Result
          challenge="ArchitecTHOR ObjectNav"
          image={
            <StaticImage
              src={`../../static/tasks/architecthor.jpg`}
              alt="ArchitecTHOR ObjectNav Task"
            />
          }
          baseline={0.19}
          zeroShot={0.31}
          fineTune={undefined}
          metricMin={0.1}
          metricMax={0.5}
          baselineText="trained on AI2-iTHOR"
          description="Semantic Object Navigation in Single-Floor House-Sized, 3D Artist-Designed Scenes"
          metric="Success Rate"
          fineTuneText={undefined}
        />
        <Result
          challenge="AI2-THOR Rearrangement"
          image={
            <StaticImage
              alt="AI2-THOR Rearrangement Task"
              src={`../../static/tasks/rearrangement-cover3.png`}
              css={css`
                margin-top: 20px;
              `}
            />
          }
          baseline={0.19}
          zeroShot={0.16}
          fineTune={0.25}
          metricMin={0.1}
          metricMax={0.35}
          baselineText="trained on AI2-THOR"
          description="Interactive Rearrangement in Room-Sized, 3D Artist-Designed Scenes"
          metric="Percentage-Fixed Strict"
          fineTuneText="fine-tuned on AI2-THOR"
        />
        <Result
          challenge="ManipulaTHOR ArmPointNav"
          image={
            <StaticImage
              src={`../../static/tasks/ArmPointNav.jpg`}
              alt="ManipulaTHOR ArmPointNav Task"
            />
          }
          baseline={0.29}
          zeroShot={0.38}
          fineTune={undefined}
          metricMin={0.1}
          metricMax={0.5}
          baselineText="trained on ManipulaTHOR"
          description="Interactive Manipulation in Room-Sized, 3D Artist-Designed Scenes"
          metric="Success Rate"
          fineTuneText="fine-tuned on ManipulaTHOR"
        />
      </div>
    </Section>
  );
}

function MaterialRandomization() {
  const [demoI, setDemoI] = useState(0);
  return (
    <Section
      parentClassNames="bg-gray-50"
      text={
        <>
          <b>Material Augmentation</b>. ProcTHOR includes 3,278 materials that
          can be used to visually augment objects, walls, floors, and ceilings.
        </>
      }
    >
      <img src={`/demo3/${demoI}.jpg`} className="w-full" />
      <TextAnnotation text="SCENE VIEW" />
      <div className="mb-12">
        {[...Array(5)].map((_, i: number) => (
          <ClickableImage
            isSelected={demoI === i}
            src={`/demo3/covers/${i}.jpg`}
            key={i}
            onClick={() => setDemoI(i)}
          />
        ))}
      </div>
    </Section>
  );
}

function ObjectDiversity() {
  return (
    <Section
      text={
        <>
          <b>Object Diversity</b>. ProcTHOR includes 1,633 interactive household
          objects across 108 categories. A small subset of these objects is
          shown below.
        </>
      }
    >
      <img src={`/assets.jpg`} className="w-full" />
      <div className="text-center text-gray-400 text-3xl">...</div>
    </Section>
  );
}

function LightingVariation(props: { leftWidth: string; rightWidth: string }) {
  const [demoI, setDemoI] = useState(0),
    [timeOfDay, setTimeOfDay] = useState("Night"),
    [pinLocation, setPinLocation] = useState(0),
    agentSpots = lightingDemoMetadata[timeOfDay][demoI]["agentSpots"];

  return (
    <div
      className="py-16 md:py-44 relative"
      css={css`
        background: ${{
          Night: "black",
          Midday: "#ccf4ff",
          GoldenHour: "#ff9922",
          BlueHour: "#0189ff",
        }[timeOfDay]};
      `}
    >
      <div
        className="max-w-screen-2xl w-10/12 mx-auto md:mb-32 absolute inset-0"
        css={css`
          mix-blend-mode: ${{
            Night: "hard-light",
            Midday: "",
            GoldenHour: "",
            BlueHour: "",
          }[timeOfDay]};
          background-size: 100%;
          z-index: 0;
          background-image: ${{
            Night: "url(/night4.jpg)",
            Midday: "url(/sunny5.jpg)",
            BlueHour: "url(/bluehour5.jpg)",
            GoldenHour: "url(/goldenHour2.jpg)",
          }[timeOfDay]};
          background-repeat: no-repeat;
          background-position: top center;
          /* max-width: 1900px; */
        `}
      />
      <div
        className="max-w-screen-2xl w-10/12 mx-auto"
        css={css`
          z-index: 2;
        `}
      >
        <p
          css={css`
            max-width: 780px;
            margin-bottom: 65px;
            font-size: 28px;
            text-align: left;
            font-family: georgia, serif;
            color: ${{
              Night: color.gray1,
              Midday: color.gray10,
              GoldenHour: color.gray10,
              BlueHour: color.gray1,
            }[timeOfDay]};
            position: relative;
          `}
        >
          <b>Lighting Variation</b>. Environment lighting can be rendered with
          significantly variation to simulate real-world lighting conditions at
          any time of day.
        </p>

        <div
          css={css`
            text-align: left;
            margin-bottom: 28px;
          `}
        >
          <div
            css={css`
              font-size: 16px;
              margin-bottom: 10px;
              z-index: 1 !important;
              position: relative;
              color: ${{
                Night: color.gray2,
                Midday: color.gray9,
                GoldenHour: color.gray9,
                BlueHour: color.gray2,
              }[timeOfDay]};
            `}
          >
            TIME OF DAY
          </div>
          <div className="scale-75 md:scale-100 origin-top-left">
            <Radio.Group
              value={timeOfDay}
              onChange={(e) => {
                setTimeOfDay(e.target.value);
              }}
              size="large"
              css={css`
                * {
                  font-size: 23px !important;
                }
                .ant-radio-button-wrapper {
                  background: ${{
                    Night: color.gray13,
                    Midday: color.light.blue1 + "77",
                    BlueHour: color.light.blue10 + "33",
                    GoldenHour: color.light.orange1 + "22",
                  }[timeOfDay]} !important;
                  color: ${{
                    Night: color.gray7,
                    Midday: color.gray7,
                    BlueHour: color.gray3,
                    GoldenHour: color.gray8,
                  }[timeOfDay]} !important;
                }
                .ant-radio-button-wrapper-checked {
                  background: ${{
                    Night: color.dark.geekblue10,
                    Midday: color.light.blue3 + "77",
                    BlueHour: color.dark.geekblue10,
                    GoldenHour: color.dark.orange10,
                  }[timeOfDay]} !important;
                  color: ${color.dark.geekblue3} !important;
                }
              `}
            >
              <Radio.Button value="Midday">Midday</Radio.Button>
              <Radio.Button value="Night">Night</Radio.Button>
              <Radio.Button value="BlueHour">Twilight</Radio.Button>
              <Radio.Button value="GoldenHour">Golden Hour</Radio.Button>
            </Radio.Group>
          </div>
        </div>

        <AiOutlineArrowRight
          css={css`
            position: absolute;
            left: ${(42.5 + 100 - 35 - 2) / 2}%;
            transform: translate(-50%, -50%);
            color: ${{
              Night: color.gray7,
              Midday: color.gray10,
              GoldenHour: color.gray10,
              BlueHour: color.gray2,
            }[timeOfDay]};

            top: 65.5%;
            font-size: 25px;

            @media (min-width: 768px) {
              top: 53.5%;
              font-size: 50px;
            }
          `}
        />
        <div
          css={css`
            width: ${props.leftWidth};
            display: inline-block;
            vertical-align: middle;
            position: relative;
            z-index: 999 !important;
          `}
        >
          <img
            src={`${awsPath}lighting-demo/${timeOfDay}/${demoI}_top.png`}
            alt=""
            css={css`
              width: 100%;
              pointer-events: none;
            `}
            className="noselect"
          />
          {agentSpots.map((agentSpot, index) => {
            return (
              <div
                className="pin w-[25px] md:w-[45px]"
                css={css`
                  border-radius: 30px;
                  position: absolute;
                  height: 60px;
                  color: ${pinLocation == index ? "white" : "black"};
                  top: ${agentSpot.y * 100}%;
                  left: ${agentSpot.x * 100}%;
                  transform: translate(-50%, -50%);
                  text-align: center;
                  font-size: 20px;
                  &:hover {
                    cursor: pointer !important;
                  }
                `}
                onClick={() => {
                  setPinLocation(index);
                }}
                key={index}
              >
                <div
                  css={css`
                    position: absolute;
                    top: -5px;
                    left: ${45 / 2}px;
                    transform: translate(-50%, -50%);
                    z-index: 99 !important;
                  `}
                  className="noselect text-xs md:text-base left-[12.5px] md:left-[22.5px]"
                >
                  {index + 1}
                </div>
                <Pin
                  fill={
                    pinLocation === index
                      ? "rgba(0, 0, 0, 0.75)"
                      : "rgba(255, 255, 255, 0.725)"
                  }
                  stroke={
                    pinLocation === index
                      ? "rgba(255, 255, 255, 0.25)"
                      : "rgba(0, 0, 0, 0.25)"
                  }
                />
              </div>
            );
          })}
        </div>
        <div
          css={css`
            width: ${props.rightWidth};
            display: inline-block;
            vertical-align: middle;
            margin-left: 20%;
          `}
        >
          <Image360
            images={[...Array(15).keys()].map(
              (i) =>
                `${awsPath}lighting-demo/${timeOfDay}/${demoI}_${pinLocation}_${
                  i + 1
                }.jpg`
            )}
          />
        </div>
        <div
          css={css`
            width: ${props.leftWidth};
            display: inline-block;
            vertical-align: top;
          `}
        >
          <div
            css={css`
              text-align: center;
              margin-top: 13px;
              margin-bottom: 13px;
            `}
          >
            {[...Array(10).keys()].map((i) => (
              <div
                key={i}
                className="w-[8px] lg:w-[15px]"
                css={css`
                  display: inline-block;
                  height: 2px;
                  border-radius: 2px;
                  background-color: ${demoI == i
                    ? {
                        Night: color.gray6,
                        Midday: color.light.blue8,
                        BlueHour: color.light.blue8,
                        GoldenHour: color.light.orange10,
                      }[timeOfDay]
                    : {
                        Night: color.gray10,
                        Midday: color.light.blue3 + "AA",
                        BlueHour: color.light.blue3 + "AA",
                        GoldenHour: color.light.orange3 + "AA",
                      }[timeOfDay]};
                  margin: 0px 2px;
                `}
              />
            ))}
          </div>
          <div
            css={css`
              overflow-x: hidden;
            `}
          >
            <div
              css={css`
                width: 200%;
                transition-duration: 450ms;
                margin-left: ${getMarginDist(demoI)}%;
              `}
            >
              {[...Array(10).keys()].map((i) => (
                <ClickableImage
                  isSelected={demoI == i}
                  className="!w-[calc(10%-2px)] !lg:w-[calc(10%-10px)] !m-[0px_1px] !lg:m-[0px_5px] p-[6px]"
                  src={`${awsPath}lighting-demo/${timeOfDay}/${i}_top_small.png`}
                  onClick={() => {
                    setDemoI(i);
                    setPinLocation(0);
                  }}
                  key={i}
                />
              ))}
            </div>
          </div>
        </div>
        <div
          css={css`
            width: ${props.rightWidth};
            margin-left: 20%;
            display: inline-block;
            vertical-align: top;
            color: ${{
              Night: color.gray7,
              Midday: color.gray7,
              BlueHour: color.gray2,
              GoldenHour: color.gray13,
            }[timeOfDay]};
          `}
          className="noselect md:mt-[-25px] lg:mt-[-30px] xl:mt-[-50px]"
        >
          <div
            css={css`
              text-align: center;
              position: relative;
              z-index: 999 !important;
            `}
          >
            <IoSwapHorizontalOutline
              css={css`
                font-size: 25px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 2px;
              `}
            />{" "}
            Drag to Rotate
          </div>
          <div
            css={css`
              margin-bottom: 10px;
              z-index: 1 !important;
              position: relative;
              margin-top: 35px;
            `}
            className="text-sm md:text-base"
          >
            PIN LOCATION
          </div>
          <div
            css={css`
              margin-top: 30px;
            `}
          >
            {agentSpots.map((agentSpot, index) => {
              const spotPrefix = `${index}_`;
              return (
                <div
                  className="pin w-[25px] md:w-[45px]"
                  css={css`
                    border-radius: 30px;
                    display: inline-block;
                    position: relative;
                    height: 60px;
                    color: ${pinLocation === index ? "white" : "black"};
                    text-align: center;
                    font-size: 20px;
                    margin-right: 0px;
                    &:hover {
                      cursor: pointer !important;
                    }
                  `}
                  onClick={() => {
                    setPinLocation(index);
                  }}
                  key={index}
                >
                  <div
                    css={css`
                      position: absolute;
                      top: -3px;
                      left: ${39.5 / 2}px;
                      transform: translate(-50%, -50%);
                      z-index: 99 !important;
                      font-size: 16px;
                      color: ${pinLocation === index
                        ? {
                            Midday: "white",
                            Night: color.gray2,
                          }[timeOfDay]
                        : {
                            Midday: color.gray6,
                            Night: color.gray9,
                          }};
                    `}
                    className="noselect text-xs md:text-base left-[11.25px] md:left-[19.75px]"
                  >
                    {index + 1}
                  </div>
                  <PinStroke
                    fill={
                      pinLocation === index
                        ? {
                            Midday: "rgba(0, 0, 0, 0.75)",
                            Night: "rgba(255, 255, 255, 0.25)",
                            BlueHour: "rgba(0, 0, 0, 0.75)",
                            GoldenHour: "rgba(0, 0, 0, 0.75)",
                          }[timeOfDay]
                        : {
                            Night: color.gray3,
                            Midday: color.gray3,
                            BlueHour: color.gray3,
                            GoldenHour: color.gray3,
                          }[timeOfDay]
                    }
                    stroke={
                      pinLocation === index
                        ? "rgba(255, 255, 255, 0.0)"
                        : color.gray5
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function FloorplanDiversity() {
  return (
    <Section
      text={
        <>
          <b>Floorplan Diversity</b>. ProcTHOR houses sample extremely diverse
          floorplans. Here are examples of sampled floorplans with between 1 and
          10 rooms.
        </>
      }
      parentClassNames="bg-gray-50"
    >
      <div
        css={css`
          font-size: 20px;
          margin-bottom: 15px;
        `}
      >
        <ColorBox className="bg-[#5ebdeb]" name="Bedroom" />
        <ColorBox className="bg-[#f0b975]" name="Bathroom" />
        <ColorBox className="bg-[#eb6a9d]" name="Kitchen" />
        <ColorBox className="bg-[#52ebbd]" name="Living Room" />
      </div>
      <img
        src={fps}
        css={css`
          width: 100%;
        `}
      />
      <div
        css={css`
          margin-top: 10px;
          text-align: center;
          font-size: 36px;
          color: ${color.gray6};
        `}
      >
        ...
      </div>
    </Section>
  );
}

function Interactivity() {
  return (
    <Section
      text={
        <>
          <b>Interactivity</b>. ProcTHOR objects are highly interactive,
          supporting object state changes, robotic arm manipulation, and
          multi-agent interaction.
        </>
      }
      parentClassNames="bg-gray-50"
    >
      <div
        css={css`
          > div {
            width: calc(96.5% / 3);
            display: inline-block;
            vertical-align: bottom;
          }
          > div:nth-of-type(1),
          > div:nth-of-type(2) {
            margin-right: calc((100% - 96.5%) / 2);
          }
        `}
      >
        <div>
          <TextAnnotation text="OBJECT STATE CHANGES" />
          <img src="/interactivity/object-states.jpg" className="w-full" />
        </div>
        <div>
          <TextAnnotation text="ROBOTIC ARM MANIPULATION" />
          <img src="/interactivity/interactivity.jpg" className="w-full" />
        </div>
        <div>
          <TextAnnotation text="MULTI-AGENT INTERACTION" />
          <img src="/interactivity/multi-agent.jpg" className="w-full" />
        </div>
      </div>
    </Section>
  );
}

function ObjectPlacement() {
  const [demoI, setDemoI] = useState(0);
  return (
    <Section
      text={
        <>
          <b>Object Placement</b>. ProcTHOR houses sample realistic and diverse
          placement of objects.
        </>
      }
    >
      <img
        src={`/placement-demo/${demoI}.jpg`}
        css={css`
          width: 100%;
        `}
      />
      <TextAnnotation text="HOUSE SELECTION" />
      <div className="mb-12">
        {[...Array(8)].map((_, i: number) => (
          <ClickableImage
            src={`/placement-demo/${i}-cover.jpg`}
            onClick={() => setDemoI(i)}
            key={i}
            isSelected={demoI === i}
          />
        ))}
      </div>
    </Section>
  );
}

function Person(props: { name: string; image: string }) {
  return (
    <div className="bg-white text-center inline-block rounded mr-4 mt-4 pt-2 lg:pt-4 px-2 lg:px-4 shadow-[0px_0px_25px_0px_#00000012] align-top w-[120px] text-sm lg:text-base lg:w-[165px]">
      <img src={props.image} className="w-full" />
      <div className="pt-1 pb-2">{props.name}</div>
    </div>
  );
}

function Team() {
  return (
    <Section
      text={
        <>
          <b>Team</b>. ProcTHOR was created by the{" "}
          <a href="https://prior.allenai.org/">PRIOR team</a> at the{" "}
          <a href="//allenai.org/">Allen Institute for AI</a>.
        </>
      }
      parentClassNames="bg-gray-50"
    >
      <Person image="/people/matt.jpg" name="Matt Deitke" />
      <Person image="/people/eli.jpg" name="Eli VanderBilt" />
      <Person image="/people/alvaro.jpg" name="Alvaro Herrasti" />
      <Person image="/people/luca.jpg" name="Luca Weihs" />
      <Person image="/people/jordi.jpg" name="Jordi Salvador" />
      <Person image="/people/kiana.jpg" name="Kiana Ehsani" />
      <Person image="/people/winson.jpg" name="Winson Han" />
      <Person image="/people/eric.jpg" name="Eric Kolve" />
      <Person image="/people/ali.jpg" name="Ali Farhadi" />
      <Person image="/people/ani.jpg" name="Ani Kembhavi" />
      <Person image="/people/roozbeh.jpg" name="Roozbeh Mottaghi" />
    </Section>
  );
}

function GetStarted() {
  return (
    <Section
      text={
        <>
          <b>Get Started</b>. ProcTHOR is fully open-source and available to the
          Embodied AI community. We are excited to see what you build!
        </>
      }
    >
      <div
        css={css`
          font-size: 20px;
          max-width: 780px;
          color: ${color.gray7};
        `}
      >
        We have provided a{" "}
        <a
          href="//colab.research.google.com/drive/1Il6TqmRXOkzYMIEaOU9e4-uTDTIb5Q78"
          target="_blank"
        >
          Google Colab notebook
        </a>{" "}
        to get started using ProcTHOR-10K. More code is available in the repos
        below.
      </div>
      <div className="mt-7 grid grid-cols-1 gap-x-3 gap-y-3 md:grid-cols-2">
        <Code
          emoji="house"
          type="Framework"
          owner="allenai"
          name="procthor"
          description="Code to Procedurally Generate Houses"
          language="Python"
        />
        <Code
          type="Dataset"
          owner="allenai"
          name="procthor-10k"
          description="The ProcTHOR-10K Houses Dataset"
          language="Python"
          emoji="house_buildings"
        />
      </div>
    </Section>
  );
}

function Pin(props: { fill: string; stroke: string }) {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 365.76 525.64"
      css={css`
        transform: translate(0, -45%);
        /* stroke: ${props.stroke}; */
        /* stroke-width: 25px; */
        /* filter: drop-shadow(0px 0px 5px ${props.stroke}); */
        filter: drop-shadow(0px 0px 5px ${props.stroke});
      `}
    >
      <defs></defs>
      <path
        css={css`
          fill: ${props.fill};
        `}
        d="M359.9,228.98c3.82-14.73,5.86-30.18,5.86-46.1C365.76,81.88,283.89,0,182.88,0S0,81.88,0,182.88c0,15.92,2.04,31.37,5.86,46.1,0,.03,0,.05,.02,.08h-.34c2.4,9.23,5.49,18.17,9.23,26.77l1.26,2.03c6.75,15.02,15.48,28.95,25.86,41.49l1.26,2.03,139.73,224.26,139.73-224.25,1.26-2.03c10.37-12.52,19.09-26.45,25.84-41.45l1.26-2.03c3.75-8.62,6.85-17.57,9.25-26.82h-.34s.01-.05,.02-.08Z"
      />
    </svg>
  );
}

function HowItWorks() {
  const [selected, setSelected] = useState(0);
  return (
    <Section
      text={
        <>
          ProcTHOR uses procedural generation to sample massively diverse,
          realistic, interactive, customizable, and performant 3D environments
          to train simulated embodied agents. Here is an example of sampling
          virtual home environments.
        </>
      }
    >
      <img
        src={
          {
            0: pg0,
            1: pg1,
            2: pg2,
          }[selected]
        }
        className="w-full"
      />
      <TextAnnotation text="HOUSE SELECTION" />
      <div className="mb-12">
        {[...Array(3)].map((_, i: number) => (
          <ClickableImage
            src={`/demo2/${i}.jpg`}
            onClick={() => setSelected(i)}
            key={i}
            isSelected={selected === i}
          />
        ))}
      </div>
    </Section>
  );
}

function PinStroke(props: { fill: string; stroke: string }) {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-36.576 -52.564 480.336 578.204"
      css={css`
        transform: translate(0, -45%);
        stroke: ${props.stroke};
        stroke-width: 15px;
      `}
    >
      <defs></defs>
      <path
        css={css`
          fill: ${props.fill};
        `}
        d="M359.9,228.98c3.82-14.73,5.86-30.18,5.86-46.1C365.76,81.88,283.89,0,182.88,0S0,81.88,0,182.88c0,15.92,2.04,31.37,5.86,46.1,0,.03,0,.05,.02,.08h-.34c2.4,9.23,5.49,18.17,9.23,26.77l1.26,2.03c6.75,15.02,15.48,28.95,25.86,41.49l1.26,2.03,139.73,224.26,139.73-224.25,1.26-2.03c10.37-12.52,19.09-26.45,25.84-41.45l1.26-2.03c3.75-8.62,6.85-17.57,9.25-26.82h-.34s.01-.05,.02-.08Z"
      />
    </svg>
  );
}

function Chart(props: { data: any; task: string }) {
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <ResponsiveContainer width="99%" height="100%">
        <AreaChart
          width={500}
          height={300}
          data={props.data}
          margin={{
            top: 35,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="houses"
            height={60}
            dy={5}
            label={{
              value: "Number of Houses (log scale)",
              dy: 15,
            }}
          />
          <YAxis
            width={60}
            dx={-5}
            label={{
              value: "SPL",
              angle: -90,
              dx: -25,
            }}
          />
          <Area
            type="monotone"
            dataKey="SPL"
            fill="#002279"
            stroke="#002279"
            activeDot={{ r: 8 }}
          />
        </AreaChart>
      </ResponsiveContainer>
      <div
        css={css`
          position: absolute;
          top: 10px;
          right: 0px;
          left: 0px;
          text-align: center;
          color: black;
        `}
      >
        {props.task} Zero-Shot SPL
      </div>
    </div>
  );
}

function Bar(props: {
  width: string;
  height: string;
  backColor: string;
  color: string;
  sep: string;
}) {
  return (
    <div
      css={css`
        position: relative;
        margin-top: ${props.sep};
        height: ${props.height};
      `}
    >
      <div
        css={css`
          height: ${props.height};
          background-color: ${props.backColor};
          position: absolute;
          width: 100%;
          top: 0px;
          border-radius: 5px 0px 5px 0px;
        `}
      />
      <div
        css={css`
          /* border-radius: 0px 15px 15px 0px; */
          border-radius: 5px 0px 5px 0px;
          height: ${props.height};
          background: ${props.color};
          width: ${props.width};
          position: absolute;
          top: 0px;
        `}
      />
    </div>
  );
}

function getMarginDist(num: number) {
  if (num >= 7) {
    return -100;
  } else if (num <= 2) {
    return 0;
  } else {
    return -20 * (num - 2);
  }
}

function ScaleImprovesPerformance() {
  return (
    <div className="py-16 md:py-44">
      <div className="max-w-screen-2xl w-10/12 mx-auto md:mb-32">
        <p
          css={css`
            max-width: 780px;
            margin-bottom: 65px;
            font-size: clamp(1rem, 4vw, 28px);
            text-align: left;
            font-family: georgia, serif;
            color: ${color.gray11};
          `}
          className="mb-4 md:mb-16"
        >
          <b>Scale Improves Performance</b>. Scaling the number of training
          houses consistently improves zero-shot performance.
        </p>
      </div>
      <div
        // className="w-[1400px] md:h-[375px]"
        className="grid grid-cols-1 h-[1500px] md:grid-cols-2 md:h-[750px] xl:grid-cols-4 xl:h-[375px]"
        css={css`
          /* height: 375px; */
          margin: auto;
          display: grid;
          /* grid-template-columns: repeat(2, 1fr); */
          max-width: 1900px;

          /* .recharts-responsive-container {
            display: inline-block;
          } */
        `}
      >
        <Chart
          data={[
            {
              houses: "10",
              SPL: 0.077,
            },
            {
              houses: "100",
              SPL: 0.102,
            },
            {
              houses: "1,000",
              SPL: 0.122,
            },
            {
              houses: "10,000",
              SPL: 0.185,
            },
          ]}
          task="ArchitecTHOR"
        />
        <Chart
          data={[
            {
              houses: "10",
              SPL: 0.04,
            },
            {
              houses: "100",
              SPL: 0.076,
            },
            {
              houses: "1,000",
              SPL: 0.157,
            },
            {
              houses: "10,000",
              SPL: 0.21,
            },
          ]}
          task="RoboTHOR"
        />
        <Chart
          data={[
            {
              houses: "10",
              SPL: 0.007,
            },
            {
              houses: "100",
              SPL: 0.05,
            },
            {
              houses: "1,000",
              SPL: 0.027,
            },
            {
              houses: "10,000",
              SPL: 0.06,
            },
          ]}
          task="Habitat"
        />
        <Chart
          data={[
            {
              houses: "10",
              SPL: 0.249,
            },
            {
              houses: "100",
              SPL: 0.352,
            },
            {
              houses: "1,000",
              SPL: 0.456,
            },
            {
              houses: "10,000",
              SPL: 0.554,
            },
          ]}
          task="AI2-iTHOR"
        />
      </div>
    </div>
  );
}

function Result(props: {
  challenge: string;
  description: string;
  image: any;
  metricMax: number;
  metricMin: number;
  baseline: number;
  zeroShot: number;
  fineTune: number;
  baselineText: string;
  metric: string;
  fineTuneText: string;
}) {
  const strokeWidth = 10,
    backStrokeColor = "rgba(0, 0, 0, 0.06)",
    sep = 20,
    baselineColor = "#bd1c5f",
    zeroShotColor = `repeating-linear-gradient(-45deg, #50de66, #50de66 3px, rgba(0, 0, 0, 0.06) 3px, rgba(0, 0, 0, 0.06) 6px)`,
    fineTuneColor = "#50de66",
    topDist = 20;

  return (
    <div className="w-full lg:w-1/2 inline-block">
      <h4>{props.challenge}</h4>
      <p
        css={css`
          font-family: sans-serif;
          margin-top: -9px;
          color: ${color.gray6};
        `}
      >
        {props.description}
      </p>
      <div
        className="w-[120px] md:w-[250px] inline-block"
        css={css`
          vertical-align: top;
        `}
      >
        {props.image}
      </div>
      <div
        className="w-[calc(100%-120px)] md:w-[calc(100%-250px)] inline-block"
        css={css`
          vertical-align: top;
          padding-left: 15px;
          padding-right: 65px;
          font-family: sans-serif;
          font-size: 14px;
        `}
      >
        <div>
          <div>
            <div
              css={css`
                margin-bottom: -15px;
                color: ${color.gray6};
              `}
            >
              {Math.round(props.metricMin * 100)}% {props.metric}
            </div>
            <div
              css={css`
                margin-bottom: -15px;
                color: ${color.gray6};
                float: right;
              `}
            >
              {Math.round(props.metricMax * 100)}%
            </div>
          </div>
          <Bar
            width={`${
              ((props.baseline - props.metricMin) /
                (props.metricMax - props.metricMin)) *
              100
            }%`}
            height={`${strokeWidth}px`}
            backColor={backStrokeColor}
            color={baselineColor}
            sep={`${topDist}px`}
          />
          <div
            css={css`
              margin-top: 3px;
            `}
          >
            <b>Baseline</b> {props.baselineText}
          </div>
          <Bar
            width={`${
              ((props.zeroShot - props.metricMin) /
                (props.metricMax - props.metricMin)) *
              100
            }%`}
            height={`${strokeWidth}px`}
            backColor={backStrokeColor}
            color={zeroShotColor}
            sep={`${sep}px`}
          />
          <div
            css={css`
              margin-top: 3px;
            `}
          >
            <b>Zero-shot</b> trained on ProcTHOR
          </div>
          {props.fineTune ? (
            <>
              <Bar
                width={`${
                  ((props.fineTune - props.metricMin) /
                    (props.metricMax - props.metricMin)) *
                  100
                }%`}
                height={`${strokeWidth}px`}
                backColor={backStrokeColor}
                color={fineTuneColor}
                sep={`${sep}px`}
              />
              <div
                css={css`
                  margin-top: 3px;
                `}
              >
                <b>Pre-Trained</b> on ProcTHOR, {props.fineTuneText}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export function Image360(props: { images: string[] }) {
  const [shownImage, setShownImage] = useState(0),
    [mouseIsDown, setMouseIsDown] = useState(false),
    [mouseX, setMouseX] = useState(0);

  return (
    <>
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: ${mouseIsDown ? 999999999999 : 0} !important;
          display: ${mouseIsDown ? "block" : "none"};

          &:hover {
            cursor: grab;
          }
        `}
        className="drag-container"
        onMouseMove={(e) => {
          if (mouseIsDown) {
            if (Math.abs(mouseX - e.clientX) > 9) {
              setMouseX(e.clientX);
              if (mouseX < e.clientX) {
                setShownImage((shownImage + 1) % props.images.length);
              } else {
                setShownImage(
                  (shownImage - 1 + props.images.length) % props.images.length
                );
              }
            }
          }
        }}
        onMouseUp={() => {
          setMouseIsDown(false);
        }}
      />
      <div
        css={css`
          position: relative;
        `}
      >
        {props.images.map((image, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                console.log("here!!!");
              }}
              onMouseDown={(e) => {
                setMouseIsDown(true);
                setMouseX(e.clientX);
              }}
              className="noselect im360"
              css={css`
                /* opacity: ${shownImage === index ? 1 : 0}; */
                /* display: ${shownImage === index ? "block" : "none"}; */
                z-index: ${shownImage === index ? 99 : -100} !important;
                width: 100%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url(${image});
                padding-top: 100%;
                position: absolute;
                top: 0px;
                margin-top: -50%;
                background-color: ${color.gray6};

                &:hover {
                  cursor: pointer;
                }
              `}
            />
          );
        })}
      </div>
    </>
  );
}

export default function Home() {
  const leftWidth = "45%",
    rightWidth = "35%";

  return (
    <>
      <Helmet>
        <title>ProcTHOR</title>
      </Helmet>
      <Cover />
      <HowItWorks />
      <MainDemo leftWidth={leftWidth} rightWidth={rightWidth} />
      <Customizability />
      <Results />
      <ScaleImprovesPerformance />
      <FloorplanDiversity />
      <ObjectDiversity />
      <MaterialRandomization />
      <ObjectPlacement />
      <LightingVariation leftWidth={leftWidth} rightWidth={rightWidth} />
      <Interactivity />
      <GetStarted />
      <Team />
      <Footer />
    </>
  );
}
